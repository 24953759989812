import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Layout from '../../layout/Layout';
import ServiceIntroduction from '../../components/services/ServiceIntroduction';
import HomeConsultation from '../../components/home/HomeConsultation';
import FunctionalityAndCasesContainer from '../../components/services/FunctionalityAndCasesContainer';
import colors from '../../theme/colors';

const infoWrapper = {
    maxWidth: '1440px',
    width: '100%',
    padding: '0px 136px',
    minHeight: '361px',
    boxSizing: 'border-box',
};
const serviceWrapper = {
    minHeight: '300px',
    display: 'flex',
    flexDirection: 'column',
    padding: '40px 0px',
    justifyContent: 'space-between',
};
const serviceContainer = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    maxWidth: '623px',
};
const osWrapper = {
    background: `linear-gradient(180deg, #26192C -31.93%, ${colors.violet} 54.16%)`,
    border: '2px solid transparent',
    borderRadius: '10px',
    minHeight: '361px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '40px',
};

const imagesContainer = {
    width: '100%',
    display: 'flex',
    alignItems: 'left',
    justifyContent: 'space-between',
};
const customOsTitle = {
    fontSize: '22px',
    lineHeight: '27px',
    fontWeight: '700',
    fontFamily: 'Montserrat',
};
const customServiceText = {
    fontSize: '22px',
    lineHeight: '32px',
    fontWeight: 600,
    fontFamily: 'Nunito',
};

const mobileIconStyle = {
    display: 'flex', justifyContent: 'center', paddingBottom: '12px',
};

const homeConsultationProps = {
    mainContainer: {
        padding: '47px 0px',
    },
};

const serviceIntroductionData = {
    title: 'Cloud Firewall y VPN',
    firstParagraph: 'Cloud Firewall dedicado para múltiples usos.',
    secondParagraph: 'Agregá seguridad en los accesos a los recursos de tu',
    secondParagraphLink: ' Virtual Private Cloud (VPC).',
    secondParagraphLinkUrl: '/services/vpc',
    gatsbyImg: (<StaticImage src="../../images/firewall-vpn_icon.svg" alt="Firewall" />),
    isButton: true,
    externalLinkButton: (
        <a
            href="https://portal.nubi2go.com/index.php?rp=/store/cloud-firewall-and-vpn"
            style={{ textDecoration: 'none' }}
            target="_blank"
            rel="noreferrer"
        >
            <Button
                variant="contained"
                aria-label="Crear ahora"
                style={{ width: '100%', height: '100%' }}
            >
                Crear ahora
            </Button>
        </a>),
};

const casesOfUseData = {
    title: 'Casos de uso',
    cases: [
        {
            image: (<StaticImage src="../../images/cloud_vpn_icon.svg" alt="VPN" />),
            text: 'VPN para usuarios',
        },
        {
            image: (<StaticImage src="../../images/tunnel_icon.svg" alt="Túnel" />),
            text: 'Túnel site to site',
        },
        {
            image: (<StaticImage src="../../images/filter_icon.svg" alt="Filtros" />),
            text: 'Reglas de filtrado',
        },
        {
            image: (<StaticImage src="../../images/nat_icon.svg" alt="NATs" />),
            text: 'NATs',
        },
        {
            image: (<StaticImage src="../../images/vpc_icon.svg" alt="VPCs" />),
            text: 'Ruteo entre VPCs',
        },
    ],
    gridColumns: 20,
    maxRowGridSize: 4,
    customPadding: '40px 136px 73px',
};

const CloudVpn = () => (
    <Layout>
        <ServiceIntroduction serviceIntroductionData={serviceIntroductionData} />
        <FunctionalityAndCasesContainer casesOfUseData={casesOfUseData} />

        <Box
            style={{
                display: 'flex',
                justifyContent: 'center',
                backgroundColor: colors.darkViolet,
            }}
            sx={(theme) => ({ [theme.breakpoints.down('md')]: { textAlign: 'center' } })}
        >
            <Box sx={(theme) => ({ [theme.breakpoints.down('sm')]: { padding: '24px 24px 0px' }, ...infoWrapper })}>
                <Grid container sx={{ minHeight: '361px' }}>
                    <Grid item xs={12} md={8} sx={{ ...serviceWrapper }}>
                        <Typography variant="subSectionH2" mb={1}>
                            Datos del servicio
                        </Typography>

                        <Grid container columns={20} sx={{ ...serviceContainer }}>
                            <Grid item xs={20} md={4} sx={(theme) => ({ [theme.breakpoints.down('md')]: { ...mobileIconStyle, paddingTop: '12px' } })}>
                                <Box width="84px">
                                    <StaticImage src="../../images/firewall_icon.svg" alt="Firewall" />
                                </Box>
                            </Grid>
                            <Grid item xs={20} md={16} px={2}>
                                <Typography
                                    sx={(theme) => ({ [theme.breakpoints.down('md')]: { paddingBottom: '18px' }, ...customServiceText })}
                                    style={{ fontWeight: 600 }}
                                >
                                    Tamaño de Firewall dependiendo del tamaño de las cargas de tráfico.
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container columns={20} sx={{ ...serviceContainer }}>
                            <Grid item xs={20} md={4} sx={(theme) => ({ [theme.breakpoints.down('md')]: { ...mobileIconStyle } })}>
                                <Box width="84px">
                                    <StaticImage src="../../images/engineer_icon.svg" alt="Ingeniero" />
                                </Box>
                            </Grid>
                            <Grid item xs={20} md={16} px={2}>
                                <Typography sx={{ ...customServiceText }} style={{ fontWeight: 600 }}>
                                    Nuestros ingenieros pueden configurarlo y administrarlo sin costo.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        md={4}
                        sx={(theme) => ({ [theme.breakpoints.down('md')]: { padding: '20px' }, ...osWrapper })}
                    >
                        <Typography sx={{ ...customOsTitle }} mb={1}>
                            Sistemas Operativos
                        </Typography>
                        <Box sx={{ ...imagesContainer }}>
                            <Box>
                                <StaticImage src="../../images/mikrotik_icon.svg" alt="Mikrotik" />
                            </Box>
                            <Box>
                                <StaticImage src="../../images/pfsense_icon.svg" alt="PfSense" />
                            </Box>
                        </Box>
                        <Box sx={{ width: '100%' }}>
                            <Typography sx={{ ...customOsTitle }} mb={1}>
                                Custom:
                            </Typography>
                            <Typography variant="boldH5" style={{ fontWeight: 500, lineHeight: '28px' }}>
                                Si ya tenés tu imagen y licencia de firewall, lo instalamos.
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
        <HomeConsultation homeConsultationProps={homeConsultationProps} />
    </Layout>
);

export default CloudVpn;
